import { useContext, Fragment ,useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as language from '../constants/languages'
import TabCategory from '../assets/svg/bottom tab bar/tabcategory.svg'
import CompanyLogo from "../assets/header_logo.svg";
import { AuthContext } from '../pages/RootPage';
import { useNavigate } from 'react-router-dom';

export default function SideBar({ drawerState }) {
    const { toggleDrawer } = useContext(AuthContext);
    const navigate = useNavigate()
    const [logo, setlogo] = useState()
    useEffect(() => {
        getCompanyInfo()
    }, [])

    async function getCompanyInfo() {

        const value = localStorage.getItem('companyInfo')

        if (value) {
            let companyInfo = JSON.parse(value);
            const Logo = companyInfo.logo !== "" ? companyInfo.logo : CompanyLogo;
            setlogo(Logo)
        }

    }
    return (
        <div>

            <Fragment >
                <Drawer
                    anchor={'left'}
                    open={drawerState}
                    onClose={() => toggleDrawer()}
                >
                    <Box
                        sx={{ width: 'auto' , height:'100%'}}
                        role="presentation"
                        onClick={() => toggleDrawer()}
                        onKeyDown={() => toggleDrawer()}
                    >
                        <Box  sx={{height:'25%', alignItems:'center', justifyContent:'center', display:'flex' }}  >
                            <div>
                                <img src={logo} height="auto" width={120} />
                            </div>
                        </Box>
                        <List style={{backgroundColor:"#1d4e4b",height:'100%', padding:30}}>

                            <ListItem disablePadding onClick={()=>navigate('/Categories')}>
                                <ListItemButton>
                                    <ListItemIcon >
                                    <img src={TabCategory} width={20} height={20}  />
                                    </ListItemIcon>
                                    <ListItemText sx={{color:'white'}} primary='Categories' />
                                </ListItemButton>

                            </ListItem>
                            <ListItem disablePadding onClick={()=>navigate('/Offers')}>
                                <ListItemButton>
                                    <ListItemIcon>
                                    <img src={require('../assets/png/terms.png')} width={20} height={25}  />
                                    </ListItemIcon>
                                    <ListItemText sx={{color:'white'}}  primary={language.offers} />
                                </ListItemButton>

                            </ListItem>

                            <ListItem disablePadding  onClick={()=>navigate('/About')}>
                                <ListItemButton>
                                    <ListItemIcon>
                                    <img src={require('../assets/png/about_us.png')} width={20} height={20}  />

                                    </ListItemIcon>
                                    <ListItemText sx={{color:'white'}} primary={language.aboutProfileLable} />
                                </ListItemButton>

                            </ListItem>


                            
                        </List>
                        

                    </Box>
                </Drawer>
            </Fragment>

        </div>
    );
}